body {
  background: $secondary;
  background: -moz-linear-gradient(327deg, $primary 0%, rgba(29,162,189,1) 75%, $secondary 100%);
  background: -webkit-linear-gradient(327deg, $primary 0%, rgba(29,162,189,1) 75%, $secondary 100%);
  background: linear-gradient(327deg, $primary 0%, rgba(29,162,189,1) 75%, $secondary 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c6cd36",endColorstr="#273459",GradientType=1);
  padding-top: 8 * $spacer;

  @include media-breakpoint-up(lg) {
    padding-top: 13 * $spacer;
  }

  &#clean-page{
    /* for includes in iframe in external pages */
    padding-top: 1.5 * $spacer;
    background: rgba(29,162,189,1); // bg can be overwritten via url param bg (hex without hash)
  }
}

.bg-section {
  padding-top: 2 * $spacer;
  padding-bottom: $spacer;
  position: relative;

  @include media-breakpoint-up(sm) {
    padding-bottom: 2 * $spacer;
  }

  @include media-breakpoint-up(md) {
    padding: 5 * $spacer 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  &.curve {
    background: #2C7E8D;
    padding-bottom: 0;
    padding-top: 0;

    &:last-child {
      margin-bottom: 0;
      &::after {
        display: none;
      }
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 18rem;
      background: url('../layout/section-bg-1.svg') bottom center repeat-x;
      background-size: auto 100%;
      margin-bottom: -2 * $spacer;
      position: absolute;
      z-index: 0;
      bottom: 100%;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 10rem;
      background: url('../layout/section-bg-2.svg') top center no-repeat;
      background-size: cover;
      position: absolute;
      z-index: 1;
      top: 100%;
    }
  }

  &.gradient {
    background: -moz-linear-gradient(347deg, #8aa857 15%, rgba(29,162,189,1) 65%, rgba(22, 80, 143, 1) 100%);
    background: -webkit-linear-gradient(347deg, #8aa857 15%, rgba(29,162,189,1) 65%, rgba(22, 80, 143, 1) 100%);
    background: linear-gradient(347deg, #8aa857 15%, rgba(29,162,189,1) 65%, rgba(22, 80, 143, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1DA2BD",endColorstr="#273459",GradientType=1);
  }
}

.bg-section.curve + .bg-section {
  padding-top: 8rem;

  @include media-breakpoint-up(lg) {
    padding-top: 13rem;
  }
}

/******* HEADER / NAVIGATION ****************/

.site-head {
  .container {
    position: relative;
  }
  .site-nav {
    background: rgba(39,53,89,0);
    transition: all 0.5s ease-in-out;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 2.5 * $spacer;
    }

    &::after {
      content: "";
      display: block;
      height: 1px;
      border-top: 1px solid $primary-light;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 3 * $spacer;
      width: 100%;

      @include media-breakpoint-up(md) {
        top: 3.75 * $spacer;
      }

      @include media-breakpoint-up(lg) {
        top: 7 * $spacer;
      }
    }

    &.inbody {
      background: rgba(39,53,89,0.75);
    }
  }
  .navbar-brand {
    display: block;
    line-height: 1;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    text-decoration: none;
    color: $primary-light;
    padding-top: 0.55 * $spacer;

    @include media-breakpoint-up(md) {
      font-size: 2.75rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3.75rem;
    }

    .title {
      color: $white;
      text-transform: uppercase;
      line-height: 1;
      display: block;
      @include media-breakpoint-up(md) {
        font-weight: $headings-font-weight;
      }
    }

    .description {
      color: $primary-light;
      display: block;
      padding: 0.3 * $spacer 0;
      margin-top: -0.1 * $spacer;

      @include media-breakpoint-up(lg) {
        margin-top: 0.4 * $spacer;
      }
    }
    .lead {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: $font-weight-bold;
      padding-right: 0.5 * $spacer;
      float: left;

      @include media-breakpoint-up(md) {
        font-weight: $headings-font-weight;
        font-size: 2.75rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 3.75rem;
      }
    }
    .small {
      display: block;
      font-size: 0.9rem;
      font-weight: $font-weight-normal;
      padding: 0.1 * $spacer 0 0;

      @include media-breakpoint-up(md) {
        padding: 0.3 * $spacer 0 0.05 * $spacer;
        font-size: $font-size-sm;
      }

      @include media-breakpoint-up(lg) {
        padding: 0.4 * $spacer 0 0.15 * $spacer;
        font-size: $h4-font-size;
      }

      &:last-child {
        padding-top: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .navbar-light .navbar-toggler {
    border-color: transparent;
    color: $white;
    position: absolute;
    z-index: 10;
    top: 0.2 * $spacer;
    right: 0;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
      top: 1.75 * $spacer;
    }

    &:focus {
      outline: none;
    }
    .navbar-toggler-icon {
      background: none;
      width: 2 * $spacer;
      height: 2 * $spacer;

      &::before, &::after {
        content: "";
        height: 3px;
        width: 100%;
        display: block;
        background: $white;
        margin-top: 7px;
        transform-origin: 9px;
      }

      &::before {
        transform: rotate(45deg);
        background: $navbar-light-color;
        @include media-breakpoint-up(md) {
          background: $white;
        }
      }

      &::after {
        transform: rotate(-45deg);
        background: $navbar-light-color;
        @include media-breakpoint-up(md) {
          background: $white;
        }
      }
    }

    &.collapsed .navbar-toggler-icon {
      &::before, &::after {
        transform: rotate(0deg);
        background: $white;
      }
    }
  }

  .navbar .navbar-collapse {
    width: 25 * $spacer;
    max-width: 100%;
    background: linear-gradient(-15deg, $white 0%, $primary-light 70%);
    position: absolute;
    z-index: 5;
    right: 0;
    top:-2.5 * $spacer;
    padding: 5 * $spacer 0;
    box-shadow: 1px 1px 10px 0 rgba(0,0,0,0.20);

    @include media-breakpoint-up(md) {
      width: 22 * $spacer;
      padding-top: 3 * $spacer;
      padding-bottom: 3 * $spacer;
      right: $grid-gutter-width * 0.5;
      top: 3.3 * $spacer;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 2 * $spacer;
      top: 4.5 * $spacer;
    }
  }

  .nav-item {
    font-size: $font-size-base * 1.1;
    padding-left: 1.5 * $spacer;
    padding-right: 1.5 * $spacer;

    &.active {
      color: black;
      font-weight: $font-weight-bold;
    }

    .nav-link{
      padding-top: 0.3 * $spacer;
      padding-bottom: 0.3 * $spacer;
    }
  }

  .legal-nav {
    padding-top: $spacer;
    padding-left: 0.5 * $spacer;

    .nav-item {
      font-size: $font-size-sm;
      padding-left: .5 * $spacer;
      padding-right: .5 * $spacer;

      .nav-link{
        color: $gray-600;
        padding-left: .5 * $spacer;
        padding-right: .5 * $spacer;
      }
    }
  }
}

#page-hero {
  @include media-breakpoint-up(md) {
    margin-top: 1rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 2rem;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 5rem;
  }

  &.site-header {
    position: relative;
    z-index: 1;
  }

  &.post-header, &.page-header {
    position: relative;
    z-index: 1;
    margin-bottom: 3 * $spacer;

    .text-col {
      display: flex;
      align-content: space-between;
      flex-direction: column;
    }
    .img-col figure {
      margin: 0.5 * $spacer 0 0 0;
    }
  }
}

/************** CARDS ***************************/

.card {
  &.bg-primary-light {
    background: $primary-light;
    color: $secondary;

    a {
      color: $secondary;
    }
  }

  .card-img-top {
    margin-bottom: $spacer;
  }

  .card-title {
    a {
      text-decoration: none;
    }
  }

  &.card-simple {
    margin-top: 0.5 * $spacer;
    margin-bottom: 1.5 * $spacer;

    .card-title {
      font-size: $font-size-base;
      margin-bottom: 0;
      a {
        color: $body-color;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}

/************** Headings ***************************/

::selection {
  background: $primary;
  color: $gray-800;
}

.page-section-head {
  margin-bottom: 2 * $spacer;

  @include media-breakpoint-up(md) {
    margin-bottom: 3 * $spacer;
  }
}

.page-section-title {
  font-size: $h1-font-size;
  text-transform: uppercase;
}

.page-section-text,
.page-lead {
  font-size: $font-size-large * 0.8;
  @include media-breakpoint-up(md) {
    font-size: $font-size-large;
  }
}

.page-section-footer {
  text-align: right;
}

h2, .h2 {
  margin-top: 2 * $spacer;
}

h3, .h3 {
  margin-top: $spacer;
}



.layout-hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.layout-social {
}

a.text-primary:hover {
  color: lighten(theme-color("primary"), 20%);
}

.list-group-item:hover {
  background: $gray-700;

}

.icon-bulb {
  position: relative;
  padding-left: 2.5 * $spacer !important;

  &::before {
    content: "";
    width: 2 * $spacer;
    height: 2.5 * $spacer;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    background: url('../layout/icon-idea.svg') no-repeat center;

    @include media-breakpoint-up(lg) {
      top: -0.5 * $spacer;
    }
  }
}


/******* HOME ***********************/

#kids-sticker {
  width: 150px;
  height: 150px;
  display: block;
  background: url('../layout/stiergreif-sticker.png') top right no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: 300px;
  white-space: nowrap;
  outline: none;
  position: relative;
  z-index: 1;
  margin-right: 0;
  margin-left: auto;
  margin-top: 1rem;
  margin-bottom: -8rem;

  @include media-breakpoint-up(sm) {
    position: absolute;
    z-index: 100;
    right: 0;
    margin: 0;
    top: 26vh;
    width: 180px;
    height: 180px;
  }

  @include media-breakpoint-up(lg) {
    width: 215px;
    height: 215px;
  }
}

.post-teaser {
  z-index: 1;

  .page-section-title {
    margin-bottom: 0;
  }

  .card {
    color: $secondary;

    a {
      color: $secondary;

      &.icon-arrow-right::after {
        background-image: url('../layout/arrow-right-dark.svg');
      }
    }

    .card-body {
      padding: $spacer;
      @include media-breakpoint-up(md) {
        padding: 1.5 * $spacer 2 * $spacer;
      }
    }
  }

  &.rewind-30 {
    .page-section-title {
      color: $primary-light;
    }
    .card {
      border-top: 2px solid $primary-light;
      background: $primary-light;
    }
  }

  &.year-review {
    .page-section-title {
      color: $primary;
    }
    .card {
      border-top: 2px solid $primary;
      background: $primary;
    }
  }
}

.video-teaser {
  z-index: 1;
  .card {
    @extend .card-simple;
    a {
      color: $white;

      &.icon-arrow-right::after {
        background-image: url('../layout/arrow-right-white.svg');
      }
    }

    .card-img-top {
      overflow: hidden;
      display: flex;
      align-items: center;

      a {
        display: block;
        position: relative;

        &::after {
          content: "";
          width: 0;
          height: 0;
          border: 2 * $spacer solid transparent;
          border-left: 4 * $spacer solid $white;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -1.5 * $spacer;
          margin-left: -1.5 * $spacer;
          transition: opacity 0.5s ease-in-out;
          opacity: 1;
        }

        &:hover::after {
          opacity: 0.5;
        }
      }
    }
  }
}

.page-home .video-teaser .card .card-img-top {
  max-height: 30vh;
}

/******* SEARCH - CHART - AREAS ****************/

.inspiration-bar {
  margin-top: 1.5 * $spacer;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-start;
  }

  h4 {
    padding: 0 $grid-gutter-width 0.5 * $spacer 2 * $spacer;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-sm * 0.9;
      display: flex;
      width: 30%;
      text-align: right;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 25%;
    }
  }

  .buttons {
    display: flex;

    .shortcut-list {
      @include media-breakpoint-up(lg) {
        white-space: nowrap;
        overflow-x: hidden;
      }

      .btn {
        margin-bottom: 0.5 * $spacer;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.source-info-bar {
  margin: 0.5 * $spacer 0 0;
  font-size: $font-size-sm;
  color: $white;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer;
  }

  .source-info {
    padding: 0.4 * $spacer $spacer 0.4 * $spacer 0;
    text-align: right;
    flex-grow: 1;
  }

  .toggle-methodik-info {
    order: -1;
    font-size: $font-size-sm;

    &::after {
      content: '';
      display: inline-block;
      background-image: url('../layout/arrow-right-white.svg');
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 0.75em;
      width: 1em;
      height: $spacer;
      transform: rotate(90deg);
      transition: transform 0.3s 0s linear;
    }

    &.collapsed::after {
      transform: rotate(0deg);
    }
  }
}

#clean-page {
  /* for includes in iframe in external pages */
  .inspiration-bar {
    margin-top: 0;
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: flex-start;
    }

    h4 {
      font-size: 0.9rem;
      @include media-breakpoint-up(sm) {
        flex: 1 0 auto;
      }
      &.icon-bulb::before {
        top: -0.5 * $spacer;
      }
    }
  }
  .source-info-bar {
    font-size: 0.9rem;
    margin-top: 0;
    .source-info {
      padding-right: 0;
    }
  }

  #search-app {
    overflow-x: hidden;

    .share {
      display: none;
    }
  }
}


.chart-title {
  margin-top: 2 * $spacer;
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
    margin-top: 3 * $spacer;
  }
}

.chart-title,
.page-section-text {

  .search-terms {
    display: block;

    @include media-breakpoint-up(md) {
      margin-left: $spacer;
      display: inline-block;
    }

    .badge.badge-light {
      font-weight: $font-weight-bold;
      border: 1px solid;
      background: rgba(0, 0, 0, 0.25);

      /* color set defined in chart.component for chart lines
  *  '#77F2FF', '#ff9999', '#B0F288', '#F1A3FF', '#45E6C8'
  */
      &:first-child {
        color: #77F2FF;
        //background: rgba(119, 242, 255, 0.2);
      }
      &:nth-child(2) {
        color: #ff9999;
        //background: rgba(255, 153, 153, 0.2);
      }
      &:nth-child(3) {
        color: #B0F288;
        //background: rgba(176, 242, 136, 0.2);
      }
      &:nth-child(4) {
        color: #F1A3FF;
        //background: rgba(255, 153, 153, 0.2);
      }
      &:nth-child(5) {
        color: #45E6C8;
        //background: rgba(255, 153, 153, 0.2);
      }
    }
  }
}

.chart-description {

  @include media-breakpoint-up(md) {
    margin-top: 1.9 * $spacer;
  }
}

/********** collapse ********/

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

@include media-breakpoint-up(md) {
  .collapse {
    background-color: transparent;
  }
}

/********** Buttons ***************/

.btn:focus {
  box-shadow: none;
}

.btn-light{
  background-color: rgba(255,255,255,0.2);
  border-color: rgba(255,255,255,0.3);
  color: $white;
  text-transform: uppercase;
  font-size: $font-size-sm;

  &:focus {
    box-shadow: none;
  }
}

.btn-link {
  color: $white;
  font-weight: $font-weight-bold;
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
}

.btn.icon-arrow-right {
  &::after {
    content: '';
    display: inline-block;
    background: url('../layout/arrow-right-white.svg') no-repeat bottom right;
    background-size: 0.75em;
    width: 1.2em;
    height: $spacer;
  }

  &.btn-sm::after {
    width: 1em;
  }
}

.pagination {
  position: relative;
  z-index: 1;

  .page-item {
    margin-left: 0.5 * $spacer;
    margin-right: 0.5 * $spacer;

    .page-link {
      border-radius: $pagination-border-radius !important;
    }
  }
}

.social .btn:last-child,
.share .btn:last-child {
  margin-right: -0.75 * $spacer;
}
/********** Embed COOKIE Consent LAYER *************/

.wp-block-embed {
  position: relative;
  .content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
/********** Wordpress Content Styles ***************/

figure.wp-block-image {
  margin: 2 * $spacer auto 2 * $spacer;
  text-align: center;
}

.wp-block-image {
  figure {
    margin-bottom: 2 * $spacer;
    margin-top: 2 * $spacer;

    &.alignright {
      float: right;
      text-align: right;
      @include media-breakpoint-down(md) {
        max-width: 50%;
      }

      &.size-medium {
        @include media-breakpoint-up(md) {
          margin-left: $grid-gutter-width;
        }
      }

      figcaption {
        text-align: right;
      }
    }

    &.alignleft {
      float: left;
      @include media-breakpoint-down(md) {
        max-width: 50%;
      }

      @include media-breakpoint-up(md) {
        margin-right: $grid-gutter-width;
      }

      figcaption {
        text-align: left;
      }
    }

    &.aligncenter {
      text-align: center;
      figcaption {
        text-align: center;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

figure.is-resized {
  &.size-medium {
    @include media-breakpoint-up(md) {
      width: 45%;
    }
  }
}

figure {
  img {
    max-width: 100%;
    height: auto;
  }
}

figcaption {
  margin-top: $spacer;
  font-size: $font-size-sm;
  opacity: 0.75;
}

.wp-block-embed__wrapper iframe {
  max-width: 100%;
}

/********** footer ********/

footer.site-footer {
  margin-top: 5 * $spacer;
  padding-bottom: 2 * $spacer;
  padding-top: 2 * $spacer;
  color: $white;
  background: #2C7E8D;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-top: 10 * $spacer;
    padding-bottom: $spacer;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 20rem;
    top: -20rem;
    background: -moz-linear-gradient(180deg,rgba(29, 162, 189, 0) 0%, rgba(29, 162, 189, 1)50%, #2C7E8D 100%);
    background: -webkit-linear-gradient(180deg,rgba(29, 162, 189, 0) 0%, rgba(29, 162, 189, 1) 50%, #2C7E8D 100%);
    background: linear-gradient(180deg,rgba(29, 162, 189, 0) 0%, rgba(29, 162, 189, 1) 50%, #2C7E8D 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c6cd36",endColorstr="#2C7E8D",GradientType=1);
    border-bottom: 1px solid $primary-light;
  }

  .btn-link {
    display: inline-block;
    outline: none;
    max-width: 55%;
    margin: $spacer 0.5 * $spacer 0.5 * $spacer;

    @include media-breakpoint-up(sm) {
      max-width: 45%;
    }

    @include media-breakpoint-up(md) {
      margin: 0 2 * $spacer 0 0 !important;
    }
  }

  .btn-icon {
    @include media-breakpoint-down(xs) {
      margin-right: 0 !important;
    }
  }

  .social {
    @include media-breakpoint-down(xs) {
      margin-top: 1.5 * $spacer;
      margin-bottom: 1.5 * $spacer;
    }
  }

  .copyright {
    margin: 0;

    @include media-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .row {
    margin-bottom: $spacer;

    .col {
      @include media-breakpoint-down(xs) {
        text-align: center !important;
      }
    }
  }
}