.wp-block-gallery,
.blocks-gallery-grid {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  // Some themes give all <ul> default margin instead of padding.
  margin: 0;

  .blocks-gallery-image,
  .blocks-gallery-item {
    // Add space between thumbnails, and unset right most thumbnails later.
    margin: 0 1em 1em 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;

    figure {
      margin: 0;
      height: 100%;

      // IE doesn't support flex so omit that.
      @supports (position: sticky) {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    // IE doesn't handle cropping, so we need an explicit width here.
    img {
      width: 100%;

      // IE11 doesn't read rules inside this query. They are applied only to modern browsers.
      @supports (position: sticky) {
        width: auto;
      }
    }

    figcaption {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-height: 100%;
      overflow: auto;
      padding: 3em 0.77em 0.7em;
      color: $white;
      text-align: center;
      font-size: 0.8em;
      opacity: 1;
      background: linear-gradient(0deg, rgba($color: $black, $alpha: 0.7) 0, rgba($color: $black, $alpha: 0.3) 70%, transparent);
      box-sizing: border-box;

      img {
        display: inline;
      }
    }
  }

  // Cropped
  &.is-cropped .blocks-gallery-image,
  &.is-cropped .blocks-gallery-item {
    a,
    img {
      // IE11 doesn't support object-fit, so just make sure images aren't skewed.
      // The following rules are for all browsers.
      width: 100%;

      // IE11 doesn't read rules inside this query. They are applied only to modern browsers.
      @supports (position: sticky) {
        height: 100%;
        flex: 1;
        object-fit: cover;
      }
    }
  }

  // On mobile and responsive viewports, we allow only 1 or 2 columns at the most.
  & .blocks-gallery-image,
  & .blocks-gallery-item {
    width: calc(50% - 1em);

    &:nth-of-type(even) {
      margin-right: 0;
    }
  }

  &.columns-1 .blocks-gallery-image,
  &.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0;
  }

  // Beyond mobile viewports, we allow up to 8 columns.
  @include break-small {
    @for $i from 3 through 8 {
      &.columns-#{ $i } .blocks-gallery-image,
      &.columns-#{ $i } .blocks-gallery-item {
        width: calc(#{ 100% / $i } - #{ 1em * ( $i - 1 ) / $i });
        margin-right: 1em;
      }
    }

    // Unset the right margin on every rightmost gallery item to ensure center balance.
    @for $column-count from 1 through 8 {
      &.columns-#{ $column-count } .blocks-gallery-image:nth-of-type(#{ $column-count }n),
      &.columns-#{ $column-count } .blocks-gallery-item:nth-of-type(#{ $column-count }n) {
        margin-right: 0;
      }
    }
  }

  // Last item always needs margins reset.
  .blocks-gallery-image:last-child,
  .blocks-gallery-item:last-child {
    margin-right: 0;
  }

  // Apply max-width to floated items that have no intrinsic width.
  &.alignleft,
  &.alignright {
    max-width: $content-width / 2;
    width: 100%;
  }

  // If the gallery is centered, center the content inside as well.
  &.aligncenter {
    .blocks-gallery-item figure {
      justify-content: center;
    }
  }
}

