/* Styles for tags-input vue component */

#search-app .search {
  position: relative;

  #search-reset {
    position: absolute;
    right: 0.4 * $spacer;
    top: 50%;
    margin-top: -1.15 * $spacer;
    border-color: transparent;
    color: $white;
    width: 2.3 * $spacer;
    height: 2.3 * $spacer;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 75%;
      left: 0.2em;
      background: $white;
      height: 1px;
      margin-top: -1px;
    }

    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

/* The input */
.tags-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tags-input input {
  flex: 1;
  background: transparent;
  border: none;
}

.tags-input input:focus {
  outline: none;
}

.tags-input input[type="text"] {
  color: $body-color;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba($white, 0.65);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba($white, 0.65);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba($white, 0.65);
}

.tags-input-wrapper-default {
  padding: .5em 2.75 * $spacer;
  background: rgba(54,67,89,0.50);
  border: 1px solid rgba(255,255,255,0.75);
  border-radius: $btn-border-radius;

  &::before {
    content: "";
    width: 1.5 * $spacer;
    height: 1.5 * $spacer;
    display: block;
    position: absolute;
    background: url('../layout/icon-search.svg') center no-repeat;
    left: 0.75 * $spacer;
    background-size: contain;
  }
}

.tags-input-wrapper-default.active {
  border: 1px solid #8bbafe;
  box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
  outline: 0 none;
}

/* The tag badges & the remove icon */


.tags-input-remove {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0.2em;
  padding: 0.6em;
  overflow: hidden;
  background: $white;
  border-radius: 1rem;
}

.tags-input-remove:focus {
  outline: none;
}

.tags-input-remove::before, .tags-input-remove::after {
  content: '';
  position: absolute;
  width: 75%;
  left: 0.15em;
  background: $body-color-secondary;

  height: 1px;
  margin-top: -1px;
}

.tags-input-remove::before {
  transform: rotate(45deg);
}
.tags-input-remove::after {
  transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
  position: relative;
  display: inline-block;
  padding: 0.35em 0.4em 0.25em;
  font-size: 85%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  overflow: hidden;
  text-overflow: ellipsis;

  .count {
    display: none;
  }

  /* color set defined in chart.component for chart lines
  *  '#77F2FF', '#ff9999', '#B0F288', '#F1A3FF', '#45E6C8'
  */
  &:first-child {
    color: #77F2FF;

    .tags-input-remove {
      background: #77F2FF;
    }
  }
  &:nth-child(2) {
    color: #ff9999;

    .tags-input-remove {
      background: #ff9999;
    }
  }
  &:nth-child(3) {
    color: #B0F288;

    .tags-input-remove {
      background: #B0F288;
    }
  }
  &:nth-child(4) {
    color: #F1A3FF;

    .tags-input-remove {
      background: #F1A3FF;
    }
  }
  &:nth-child(5) {
    color: #45E6C8;

    .tags-input-remove {
      background: #45E6C8;
    }
  }
}

.tags-input-badge-pill {
  padding-left: 0;
  padding-right: 1.5em;
  border-radius: 10em;
  margin-right: 0.5rem;
}

.tags-input-badge-selected-default {
  color: $body-color;
  background-color: transparent;
}

/* Typeahead */
.typeahead-hide-btn {
  color: #999 !important;
  font-style: italic;
}

/* Typeahead - badges */

.typeahead-badges {
  position: absolute;
  z-index: 1;
}

.typeahead-badges > span {
  cursor: pointer;
  margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  max-width: 400px;
  z-index: 1000;
}

.typeahead-dropdown li {
  padding: .25em 1em;
  cursor: pointer;

  .count {
    display: block;
    float: right;
    color: $gray-500;
  }
}

/* Typeahead elements style/theme */
.tags-input-typeahead-item-default {
  color: #fff;
  background-color: $body-color-secondary;
}

.tags-input-typeahead-item-highlighted-default {
  color: #fff;
  background-color: #007bff;
}
