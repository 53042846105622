/* Styles for charts vue component */
/* no variables here!!! */

#chart-container {
  max-width: 1200px;
}

.chart-app-view {
  .share  {
    display: none;
  }

  .apexcharts-subtitle-text {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.chart-app {
  position: relative;

  .share {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      top: 1.8 * $spacer;
      left: 15px; // chart left space
    }
  }

  .custom-tooltip {
    .tooltip-head {
      padding-left: 3rem;
    }
    .tooltip-head,
    .custom-tooltip-body .line {
      display: flex;

      span {
        flex-grow: 1;
        &.values {
          text-align: right;
        }
      }
    }
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background: $secondary;
    background: rgba(54,67,89,0.80);
    border: 1px solid rgba(255,255,255,0.50);
    color: $white;
    padding: 5px;
    border-radius: 3px;

    .tooltip-head {
      margin-bottom: 0.5 * $spacer;
      border-bottom: 1px solid rgba(255,255,255,0.50);
    }
  }

  .apexcharts-gridlines-horizontal {opacity: 0.1;}
  .apexcharts-gridlines-vertical {
    opacity: 0.5;
    *:nth-child(2n) {
      opacity: 0;
    }
  }

  .apexcharts-xaxis-label:nth-child(2n) {
    opacity: 0;
  }

  .apexcharts-xaxistooltip {
    background: #F5FBC3; /* rgba(54,67,89,0.8) */
    padding: 4px 4px 1px 4px;
    border: none;
    color: #364359;
    font-weight: 500;
    margin-left: 7px;

    &.apexcharts-xaxistooltip-bottom:after,
    &.apexcharts-xaxistooltip-bottom:before {
      display: none;
    }
  }
}
