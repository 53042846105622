/* rajdhani-500 - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/rajdhani-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Rajdhani Medium'), local('Rajdhani-Medium'),
  url('../fonts/rajdhani-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/rajdhani-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/rajdhani-v9-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/rajdhani-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/rajdhani-v9-latin-500.svg#Rajdhani') format('svg'); /* Legacy iOS */
}
/* rajdhani-600 - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/rajdhani-v9-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Rajdhani SemiBold'), local('Rajdhani-SemiBold'),
  url('../fonts/rajdhani-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/rajdhani-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/rajdhani-v9-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/rajdhani-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/rajdhani-v9-latin-600.svg#Rajdhani') format('svg'); /* Legacy iOS */
}
/* rajdhani-700 - latin */
@font-face {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/rajdhani-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Rajdhani Bold'), local('Rajdhani-Bold'),
  url('../fonts/rajdhani-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/rajdhani-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/rajdhani-v9-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/rajdhani-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/rajdhani-v9-latin-700.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

// Import our custom variables
@import "./custom";

// Manual Bootstrap Import

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/images";
// @import "../../../../node_modules/bootstrap/scss/code";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/forms";
@import "../../../../node_modules/bootstrap/scss/buttons";
// @import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../node_modules/bootstrap/scss/button-group";
@import "../../../../node_modules/bootstrap/scss/input-group";
// @import "../../../../node_modules/bootstrap/scss/custom-forms";
@import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/navbar";
// @import "../../../../node_modules/bootstrap/scss/card";
// @import "../../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../../node_modules/bootstrap/scss/pagination";
@import "../../../../node_modules/bootstrap/scss/badge";
// @import "../../../../node_modules/bootstrap/scss/jumbotron";
// @import "../../../../node_modules/bootstrap/scss/alert";
// @import "../../../../node_modules/bootstrap/scss/progress";
// @import "../../../../node_modules/bootstrap/scss/media";
@import "../../../../node_modules/bootstrap/scss/list-group";
// @import "../../../../node_modules/bootstrap/scss/close";
// @import "../../../../node_modules/bootstrap/scss/toasts";
@import "../../../../node_modules/bootstrap/scss/modal";
// @import "../../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../../node_modules/bootstrap/scss/popover";
@import "../../../../node_modules/bootstrap/scss/carousel";
// @import "../../../../node_modules/bootstrap/scss/spinners";
@import "../../../../node_modules/bootstrap/scss/utilities";

//Import Animate.css custom animations
@import "../../../../node_modules/animate.css/source/base";
@import "../../../../node_modules/animate.css/source/fading_entrances/fadeInUp";

//Import coding color in prism folder
@import "../prism/prism-xonokai";

// Styles after bootstrap and other css has loaded.
@import "./features/wp-content/wp-content";
@import "./overrides";
@import "./features/charts";
@import "./features/tags-input";
