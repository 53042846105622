.wp-block-media-text {
	margin-top: 2 * $spacer;
	margin-bottom: 2 * $spacer;
	// This block's direction should not be manipulated by rtl, as the mediaPosition control does.
	/*!rtl:begin:ignore*/
	direction: ltr;
	/*!rtl:end:ignore*/
	display: grid;
	grid-template-columns: 50% 1fr;
	grid-template-rows: auto;

	&.has-media-on-the-right {
		grid-template-columns: 1fr 50%;
	}
}

.wp-block-media-text.is-vertically-aligned-top {
	.wp-block-media-text__content,
	.wp-block-media-text__media {
		align-self: start;
	}
}
.wp-block-media-text,
.wp-block-media-text.is-vertically-aligned-center {
	.wp-block-media-text__content,
	.wp-block-media-text__media {
		align-self: center;
	}
}

.wp-block-media-text.is-vertically-aligned-bottom {
	.wp-block-media-text__content,
	.wp-block-media-text__media {
		align-self: end;
	}
}

.wp-block-media-text .wp-block-media-text__media {
	/*!rtl:begin:ignore*/
	grid-column: 1;
	grid-row: 1;
	/*!rtl:end:ignore*/
	margin: 0;
}

.wp-block-media-text .wp-block-media-text__content {
	direction: ltr;
	/*!rtl:begin:ignore*/
	grid-column: 2;
	grid-row: 1;
	/*!rtl:end:ignore*/
	padding: 0 8% 0 8%;
	word-break: break-word;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
	/*!rtl:begin:ignore*/
	grid-column: 2;
	grid-row: 1;
	/*!rtl:end:ignore*/
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
	/*!rtl:begin:ignore*/
	grid-column: 1;
	grid-row: 1;
	/*!rtl:end:ignore*/
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
	max-width: unset;
	width: 100%;
	vertical-align: middle;
}

.wp-block-media-text.is-image-fill figure.wp-block-media-text__media {
	height: 100%;
	min-height: 250px;
	background-size: cover;
}

.wp-block-media-text.is-image-fill figure.wp-block-media-text__media > img {
	// The image is visually hidden but accessible to assistive technologies.
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
/*
* Here we here not able to use a mobile first CSS approach.
* Custom widths are set using inline styles, and on mobile,
* we need 100% width, so we use important to overwrite the inline style.
* If the style were set on mobile first, on desktop styles,
* we would have no way of setting the style again to the inline style.
*/
@media (max-width: #{ ($break-small) }) {
	.wp-block-media-text.is-stacked-on-mobile {
		grid-template-columns: 100% !important;
		.wp-block-media-text__media {
			grid-column: 1;
			grid-row: 1;
		}
		.wp-block-media-text__content {
			grid-column: 1;
			grid-row: 2;
		}
	}
}
